.home_carousel {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 329vw;
    left: 0;
    top: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);

    @media screen and (min-width: 992px) {
      height: 186px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 192px;
    left: 0;
    bottom: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);

    @media screen and (min-width: 992px) {
      height: 270px;
    }
  }

  &__scrollMain{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 80;
    width: 100%;
    padding: 0 5vw 7vw;
    box-sizing: border-box;
  }

  &__scroll_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      border: none;
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 12px;
      line-height: 42px;
      text-align: center;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #FFFFFF;
      background-color: transparent;
      cursor: pointer;

    &__top_motif {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform-origin: 0 0;

      &__line {
        display: block;
        width: 1px;
        height: 15px;
        background-color: white;
      }
    }

    &__text {
      transform-origin: 50% 50%;
    }

    &__bottom_motif {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__line {
        display: block;
        width: 1px;
        height: 25px;
        background-color: white;
        transform-origin: 0 0;
      }

      &__icon {
        display: block;
        width: 19px;
        height: 10px;
        transform: translateY(-8px);
        transform-origin: 100% 100%;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    list-style-type: none;
    height: 100vh;
    margin: 0;
    padding: 0;

    &__item {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;

      &__image {
        object-fit: cover;
        object-position: cover;
        width: 100vw;
        height: 100vh;
      }

      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 79;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 300;
        font-size: 8vw; /* Adjust font size with vw for better scaling */
        line-height: 10vw;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

        @media screen and (min-width: 992px) {
          padding: 54px 0 0 54px;
          font-size: 110px;
          line-height: 105px;
        }

        em {
          font-style: normal;
          font-weight: 700;
          white-space: nowrap;

          @media screen and (min-width: 992px) {
            padding: 0 160px 0 50px;
          }
        }

        span {
          white-space: nowrap;

          @media screen and (min-width: 992px) {
            padding: 0 0 0 50px;
          }
        }
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    list-style-type: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 80;
    width: 100%;
    padding: 0 5vw 7vw;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
      justify-content: flex-start;
      padding: 0 1vw 2vw 6vw;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 0 3vw;
      position: relative;

      @media screen and (min-width: 992px) {
        margin: 0 35px 0 0;
        padding: 0 0 14px;
      }

      &__link {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        border: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 5vw;
        letter-spacing: 0.05em;
        text-decoration: none;
        color: #FFFFFF;
        background-color: transparent;
        cursor: pointer;
        transition: color 400ms, text-shadow 400ms ease-in;

        @media screen and (min-width: 992px) {
          font-size: 20px;
          line-height: 23px;
        }

        &:hover {
          color: #F8C973;
          text-shadow: 0px 0px 14px #f8c973;
        }

        span {
          display: block;
        }
      }

      &__timer {
        display: block;
        width: 100%;
        height: 4px;
        margin: 8px 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow-x: hidden;

        @media screen and (min-width: 992px) {
          margin: 10px 0 0;
        }

        &__background {
          display: block;
          width: 100%;
          height: 4px;
          margin: 10px 0 0;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.4);
        }

        &__progress {
          display: block;
          height: 4px;
          width: 0;
          background-color: white;
        }
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 20vw; /* Adjusted top position for responsiveness */
    left: 0;
    z-index: 89;
    width: 100vw;
    padding: 10px 0;

    &__btn {
      margin: 0 20px;

      &__image {
        width: auto;
        height: 24px;
        display: block;
      }
    }
  }

  
}

@media screen and (max-width: 768px) {
  .home_carousel {
    height: 100vh;
  }

  .home_carousel__list__item__title {
    align-items: center;
    justify-content: start;
    top: 15vh; 
  }


  .home_carousel__cta {
    top: 27vh; /* Adjusted positioning for mobile */
  }

  .home_carousel__cta__btn__image {
    height: 20px; /* Reduce button size */
  }
}
