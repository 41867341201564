.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media screen and (min-width: 992px) {
    padding: 30px 54px 30px 39px;
  }

  &.light {

    .header__logo__image {
      // filter: invert(1);
    }

    .header__content__cta__logo {
      filter: invert(1);
    }

    .header__content__partners_btn {
      color: #1C1C1C;
      border-color: #1C1C1C;

      .btn_hover_state_background {
        background-color: #1C1C1C;
      }

      .btn_hover_state_text {
        color: #FFFFFF;
      }
    }

    .header__content__menu_btn {

      .header__content__menu_btn__bar {
        background-color: #1C1C1C;
      }
    }
  }

  &__logo {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &__image {
      width: 106px;
      height: 67px;

      @media screen and (min-width: 992px) {
        width: 117px;
        height: 74px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__cta {
      margin: 0 40px 0 0;

      &__logo {
        width: 99px;
        height: 28px;
      }
    }

    &__partners_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 36px;
      margin: 0 17px 0 0;
      border: 1px solid #FFFFFF;
      border-radius: 18px;
      box-sizing: border-box;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 9px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;

      @media screen and (min-width: 992px) {
        width: 211px;
        height: 50px;
        margin: 0 67px 0 0;
        font-size: 15px;
        line-height: 18px;
        border-radius: 60px;
      }
    }

    &__menu_btn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 26px;
      height: 19px;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer;

      &:hover {

        .header__content__menu_btn__bar {

          &:first-child {
            transform: translateY(-3px);
          }

          &:last-child {
            transform: translateY(3px);
          }
        }
      }

      &__image {
        display: block;
      }

      &__bar {
        width: 26px;
        height: 3px;
        display: block;
        background-color: white;
        transition: transform 200ms;

        &:last-child {
          transition-delay: 200ms;
        }
      }
    }
  }
}
