.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  // background-color: #8ED2C8;
  background-color: #1C1C1C;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 992px) {
    height: 100vh;
  }

  &__content {
    position: relative;
    padding: 117px 30px 100vw;
    // background-position: right bottom;
    // background-repeat: no-repeat;
    // background-size: 100% auto;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
      width: 85vw;
      height: 100vh;
      // padding: 300px 0 192px;
      padding: 38vh 0 24vh;
      // background-size: auto 75%;
    }

    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: right bottom;

      @media screen and (min-width: 992px) {
        width: 85vw;
        height: 100vh;
      }
    }

    &__title {
      font-family: 'Raleway';
      font-style: normal;
      font-size: 38px;
      line-height: 42px;
      text-transform: uppercase;
      color: white;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      max-width: 536px;
      margin: 0 0 10px;

      @media screen and (min-width: 992px) {
        // font-size: 60px;
        font-size: 7.5vh;
        // line-height: 70px;
        line-height: 9vh;
        // margin: 0 0 20px;
        margin: 0 0 2.5vh;
      }
    }

    &__description {
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      color: white;
      max-width: 570px;
      margin: 0 0 30px;

      @media screen and (min-width: 992px) {
        // font-size: 20px;
        font-size: 2.5vh;
        // line-height: 23px;
        line-height: 3vh;
        // margin: 0 0 50px;
        margin: 0 0 6.5vh;
      }
    }

    &__link {
      display: inline-block;
      // width: 115px;
      width: 14.5vh;
      // height: 50px;
      height: 6.5vh;
      // padding: 0 62px;
      padding: 0 7.5vh;
      font-family: 'Roboto';
      font-weight: 500;
      // font-size: 15px;
      font-size: 2vh;
      // line-height: 50px;
      line-height: 6.5vh;
      letter-spacing: 0.05em;
      color: #1C1C1C;
      text-decoration: none;
      background-color: white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      // border-radius: 50px;
      border-radius: 6.5vh;

      @media screen and (min-width: 992px) {
        // height: 61px;
        height: 7.5vh;
        // border-radius: 60px;
        border-radius: 7.5vh;
        // line-height: 60px;
        line-height: 7.5vh;
      }
    }
  }
}
