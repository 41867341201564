.map {
  // position: relative;
  // width: 100%;
  // height: 100%;
  // display: block;

  &__close_menu_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    padding: 0;
    border: none;
    border-radius: 30px;
    background-color: white;
    cursor: pointer;
    transition: background-color 400ms;

    @media screen and (min-width: 992px) {
      top: 30px;
      right: 54px;
    }

    &__image {
      display: block;
    }
  }

  &__marker {
    font-size: 9px;
    line-height: 9px;
    padding: 3px 7px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: white;
  }
}
