.page_hero {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 186px;
    left: 0;
    top: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 382px;
    left: 0;
    bottom: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: absolute;
    bottom: 40px;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      font-size: 70px;
      line-height: 82px;
    }

    &__main {
      font-style: normal;
      font-weight: 700;
      white-space: nowrap;
    }

    &__sub {
      white-space: nowrap;
      display: block;
      margin-bottom: 53px;
      line-height: 40px;

      @media screen and (min-width: 992px) {
        line-height: 82px;
      }
    }

    &__scroll_btn {
      display: none;

      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        border: none;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 12px;
        line-height: 42px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        background-color: transparent;
        cursor: pointer;
      }

      &__top_motif {
        display: flex;
        flex-direction: column;
        align-items: center;
        transform-origin: 0 0;

        &__line {
          display: block;
          width: 1px;
          height: 15px;
          background-color: white;
        }
      }

      &__text {
        transform-origin: 50% 50%;
      }

      &__bottom_motif {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__line {
          display: block;
          width: 1px;
          height: 25px;
          background-color: white;
          transform-origin: 0 0;
        }

        &__icon {
          display: block;
          width: 19px;
          height: 10px;
          transform: translateY(-8px);
          transform-origin: 100% 100%;
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 25px;
    z-index: 99;
    width: 211px;
    height: 50px;
    margin: 40px 67px 0 0;
    border: none;
    border-radius: 60px;
    box-sizing: border-box;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #1C1C1C;
    text-decoration: none;
    background-color: white;
    transform: translateX(-50%);
    cursor: pointer;

    @media screen and (min-width: 992px) {
      right: 75px;
      bottom: 46px;
      left: auto;
      margin: 0;
      transform: none;
    }

    .btn_hover_state_background {
      background-color: #F8C973;
    }
  }
}
