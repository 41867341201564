.form {

  &__title {
    margin: 0;
    padding: 0;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    // font-size: 38px;
    // line-height: 45px;
    font-size: 9.7vw;
    line-height: 11.6vw;
    text-transform: uppercase;
    color: #FFFFFF;

    @media screen and (min-width: 992px) {
      // padding: 0 0 40px;
      padding: 0 0 5vh;
      // font-size: 60px;
      // line-height: 70px;
      font-size: 7.5vh;
      line-height: 9vh;
    }
  }

  &__subheading {
    margin: 0;
    padding: 0;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 45px;
    color: #FFFFFF;

    @media screen and (min-width: 992px) {
      padding: 0 0 40px;
      font-size: 30px;
      line-height: 35px;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;

    &__group {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 992px) {
        display: grid;
        gap: 80px;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__input {
      flex: 1;
      // margin: 40px 0 0;
      margin: 5vh 0 0;
      padding: 0;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #838383;
      border-left: none;
      font-family: 'Raleway';
      font-weight: 400;
      // font-size: 16px;
      font-size: 2vh;
      // line-height: 28px;
      line-height: 3.5vh;
      letter-spacing: -0.02em;
      color: white;
      background-color: transparent;
      border-radius: 0;
      -webkit-appearance: none;

      @media screen and (min-width: 992px) {

      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }

      &:focus {
        outline: 0;
      }

      option {
        background-color: #1C1C1C;
      }
    }

    &__text {
    }

    &__email {
    }

    &__select {
    }

    &__textarea {
      // height: 28px;
      height: 5vh;
    }

    &__button {
      align-self: flex-start;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 175px;
      width: 22vh;
      // height: 50px;
      height: 6.5vh;
      // margin: 40px 67px 0 0;
      margin: 5vh 67px 0 0;
      border: none;
      // border-radius: 60px;
      border-radius: 7.5vh;
      box-sizing: border-box;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      // font-size: 15px;
      font-size: 2vh;
      // line-height: 18px;
      line-height: 2.5vh;
      letter-spacing: 0.05em;
      text-align: center;
      color: #1C1C1C;
      text-decoration: none;
      background-color: white;
      cursor: pointer;

      @media screen and (min-width: 992px) {

      }

      .btn_hover_state_background {
        background-color: #F8C973;
      }
    }
  }

  &__message {
    font-family: "Raleway";
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: white;
  }
}
