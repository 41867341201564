.galleries {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 60px 20px 0;

    @media screen and (min-width: 992px) {
      display: grid;
      gap: 106px;
      grid-template-columns: repeat(2, 1fr);
      max-width: 83vw;
      padding: 120px 0 0;
    }

    &__item {
      display: flex;
      margin: 0;
      padding: 0 0 60px;

      @media screen and (min-width: 992px) {
        padding: 0 0 120px;
      }

      &:hover {

        .galleries__list__item__title {

          &:after {
            // opacity: 1;
            animation-duration: 0.4s;
            animation-name: hoverMove;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }

      &:nth-child(even) {
        justify-content: flex-end;

        @media screen and (min-width: 992px) {
          justify-content: flex-start;
        }
      }

      a {
        text-decoration: none;
      }

      &__image {
        display: block;
        box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.08);
      }

      &__title {
        display: flex;
        justify-content: space-between;
        font-family: "Raleway";
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-transform: capitalize;
        color: #1C1C1C;
        margin: 0;
        padding: 20px 0 5px;

        &:after {
          content: '';
          width: 10px;
          height: 23px;
          margin: 0 0 0 20px;
          display: inline-block;
          background-image: url(../../assets/svg/right-arrow-icon.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          // opacity: 0;
          // transition: opacity 200ms ease-in;
        }
      }

      &__date {
        display: block;
        font-family: "Raleway";
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #1C1C1C;
      }
    }
  }
}

@keyframes hoverMove {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(5px);
  }
}
