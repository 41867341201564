@media screen and (max-width: 768px) {
    .home_carousel {
      height: 100vh;
    }
  
    .home_carousel__list__item__title {
      align-items: center;
      justify-content: start;
      top: 15vh; 
    }
  
  
    .home_carousel__cta {
      top: 15vh; /* Adjusted positioning for mobile */
    }
  
    .home_carousel__cta__btn__image {
      height: 20px; /* Reduce button size */
    }
  }