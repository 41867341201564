
.body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  height: 100vh;
}
.container {
  text-align: center;
  padding: 20px;
  background-color: #000;
  color: #fff;
}

.Textheader {
  justify-content: center;
  font-size: 2em;
  margin-bottom: 10px;
}

.subHeader {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for small screens */
  justify-content: center;
  gap: 20px;
}

.option {
  position: relative;
  width: 100%; /* Full width for smaller screens */
  max-width: 500px; /* Maintain a max width for larger screens */
  cursor: pointer;
}

.image {
  width: 100%;
  border-radius: 30px;
}

.optionText {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 1.5em;
  padding: 5px 10px;
  border-radius: 5px;
}

.arrowButton {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.arrowButton:hover {
  background-color: darkred;
}

.arrow {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .Textheader {
    font-size: 1.8em; /* Slightly smaller for medium screens */
  }

  .subHeader {
    font-size: 1.1em; /* Adjust subheader size */
  }

  .option {
    width: 90%; /* Option takes up more space on medium screens */
  }

  .optionText {
    font-size: 1.3em; /* Reduce font size for smaller screens */
  }

  .arrowButton {
    width: 35px;
    height: 35px;
  }

  .arrow {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .Textheader {
    margin-top: 150px;
    font-size: 1.5em; /* Smaller header for mobile */
  }

  .subHeader {
    font-size: 1em; /* Further adjust subheader */
  }

  .option {
  }

  .optionText {
    font-size: 1.1em; /* Adjust text size for mobile */
    bottom: 15px; /* Slightly adjust positioning */
    left: 15px;
  }

  .arrowButton {
    right: 30px;
    width: 30px;
    height: 30px;
  }

  .arrow {
    font-size: 16px;
  }
}
