html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .video-container2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* Ensure the container takes up the full viewport height */
    overflow: hidden;
  }
  
  .background-video2 {
    width: 40vh;
    height: 40vh;
    object-fit: cover;
  }
  