.adoozy_power_products {
  display: flex;
  flex-direction: column;
  position: relative;
  // z-index: 2;
  padding: 70px 0 120px;
  background: white;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    // align-items: center;
    padding: 82px 0 193px;
  }

  &__title {
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;
    padding: 0 7.5vw;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    text-align: center;
    color: #1C1C1C;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      width: 85vw;
      padding: 0 7.5vw;
      font-size: 70px;
      line-height: 82px;
    }

    span {
      display: flex;
      align-items: center;
      font-family: "Raleway";
      font-weight: 400;
      font-size: 12px;
      line-height: 45px;
      letter-spacing: -0.02em;
      color: #1C1C1C;

      @media screen and (min-width: 992px) {
        font-size: 16px;
        line-height: 82px;
      }

      img {
        display: inline-block;
        margin-left: 15px;
      }
    }
  }

  &__product_list {
    // max-width: 85vw;
    // overflow-x: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    position: relative;

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      z-index: 99;
      width: 40vw;
      height: 100%;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      transform: translateY(-50%);

      @media screen and (min-width: 992px) {
         width: 20vw;
      }

      &.v_prev {
        left: 0;

        img {
          transform: rotate(180deg);
        }
      }

      &.v_next {
        right: 0;
      }
    }

    &__items {
      display: flex;
      flex-wrap: nowrap;
      list-style-type: none;
      margin: 35px 15vw;
      padding: 0;
      transition: transform 400ms ease-in;
      z-index: 98;

      @media screen and (min-width: 992px) {
        width: 85vw;
        margin: 35px 7.5vw;
      }
    
      &:after {
        content: '';
        display: block;
        flex: 1 0 1px;
      }

      &__item {
        // flex: 1 0 78vw;
        flex: 1 0 70vw;
        display: flex;
        flex-direction: column;
        margin-right: 15vw;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        box-sizing: border-box;
        background-color: black; //i need to look into this part
        width: 100%;

        @media screen and (min-width: 992px) {
          // flex: 1 0 55vw;
          flex: 1 0 85vw;
          flex-direction: row;
          align-items: center;
          // margin-right: 50px;
          margin-right: 7.5vw;
        }

        &__image {
          display: flex;
          border-radius: 100px 100px 0px 0px;
          display: block;

          @media screen and (min-width: 992px) {
            flex: 1;
            border-radius: 100px 0px 0px 100px;
            width: 33.3333%;
            height: 100%;
          }
  
        }

        &__content {
          margin: 20px 0px 20px 0px;
          
          @media screen and (min-width: 992px) {
            flex: 1;
          }

          &__name {
            margin: 16px 10px 15px;
            font-family: 'Raleway';
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            text-transform: capitalize;
            color: #ffffff;
            padding: 0 7.5vw;

            @media screen and (min-width: 992px) {
              margin: 0 0 19px;
              padding: 0 7.5vw;
            }
          }

          &__description {
            margin: 0 10px 15px;
            font-family: 'Raleway';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #ffffff;
            padding: 0 7.5vw;

            @media screen and (min-width: 992px) {
              font-size: 18px;
              line-height: 28px;
              padding: 0 7.5vw;
            }
          }

          &__partners_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            border: 1px solid #FFFFFF;
            border-radius: 18px;
            box-sizing: border-box;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 9px;
            line-height: 9px;
            letter-spacing: 0.05em;
            text-align: center;
            color: #FFFFFF;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;
            margin: 0 7.5vw 50px;
      
            @media screen and (min-width: 992px) {
              width: 300px;
              height: 50px;
              font-size: 15px;
              line-height: 18px;
              border-radius: 60px;
              margin: 0 7.5vw;
            }
           
          }

          &__enquiry_btn {
            display: flex;
            align-items: center;
            padding: 0;
            border: none;
            font-family: 'Raleway';
            font-weight: 700;
            font-size: 0.7em;
            line-height: 42px;
            letter-spacing: -0.02em;
            color: #ffffff;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;
            padding: 0 7.5vw;
            
            @media screen and (min-width: 992px) {
              font-size: 0.8em;
            }

            &:before {

              @media screen and (min-width: 992px) {
                content: '';
                width: 4px;
                height: 9px;
                margin: 0 10px 0 0;
                display: inline-block;
                background-image: url(../../assets/svg/right-arrow-black.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }
}
