.adoozy_power_how_it_works {
  position: relative;
  z-index: 1;
  background-color: white;

  &__header {
    img {
      width: 100%;
      object-fit: cover;

      @media screen and (max-width: 480px) {
        width: 100%;
      } 
    }
  }
  
  &__subheading {
    font-family: 'Raleway';
    font-size: 15px;
    line-height: 36px;
    text-align: center;
    color: #000;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    margin: auto;
  
    @media screen and (min-width: 992px) {
      font-size: 20px;
      line-height: 41px;
    }
}

  &__title {
    height: 82px;
    margin: 0;
    padding: 70px 0 43px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    text-align: center;
    color: #1C1C1C;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    // box-sizing: border-box;

    @media screen and (min-width: 992px) {
      font-size: 70px;
      line-height: 82px;
      padding: 127px 0 35px;
    }

    span {
      display: block;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: transform 400ms ease-in;

    @media screen and (min-width: 992px) {
      padding: 100px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: column-reverse;
      width: 100vw;
      margin: 0 0 60px;
      padding: 0 20px;
      box-sizing: border-box;

      @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: center;
        // height: 80vh;
        margin: 0;
        padding: 0 0 100px;
      }

      &:nth-child(even) {
        flex-direction: column-reverse;
        align-items: flex-end;

        @media screen and (min-width: 992px) {
          flex-direction: row-reverse;
          align-items: center;
        }

        .adoozy_power_how_it_works__list__item__image {

          @media screen and (min-width: 992px) {
            margin: 0 70px 0 0;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 527px;

        &__title {
          max-width: fit-content;
          margin: 0;
          font-family: 'Raleway';
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          color: #1C1C1C;
          text-transform: capitalize;

          @media screen and (min-width: 992px) {
            font-size: 20px;
            line-height: 23px;
          }

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            margin: 8px 0 0;
            background-color: #1C1C1C;
          }
        }

        &__description {
          margin: 15px 0 0;
          font-family: 'Raleway';
          font-weight: 400;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.02em;
          color: #1C1C1C;

          @media screen and (min-width: 992px) {
            margin: 22px 0 0;
            font-size: 32px;
            line-height: 42px;
          }
        }

        &__links {
          list-style-type: none;
          margin: 13px 0 0 20px;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          
          @media screen and (min-width: 992px) {
            margin: 21px 0 0 0;
          }

          &__item {
            align-items: center;
            margin: 0 0 10px;
            padding: 0;

            &__button {
              display: flex;
              align-items: center;
              padding: 0;
              border: none;
              font-family: 'Raleway';
              font-weight: 700;
              font-size: 14px;
              line-height: 42px;
              letter-spacing: -0.02em;
              color: #1C1C1C;
              text-decoration: none;
              background-color: transparent;
              cursor: pointer;

              &__icon {
                margin: 0 10px 0 0;
                display: inline-block;
              }
            }
          }
        }
      }

      &__image {
        width: 72vw;
        margin: 0 0 20px;
        border-radius: 100%;

        @media screen and (min-width: 992px) {
          align-self: center;
          width: 420px;
          margin: 0 0 0 70px;
        }
      }
    }
  }
}
