.adoozy_power_pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  padding: 90px 0 65px;
  height: 100%;

  @media screen and (min-width: 992px) {
    padding: 0px 0px px;
  }

  &__title {
    margin: 0 0 50px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: black;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      font-size: 70px;
      line-height: 82px;
    }
  }

  &__subheading {
    margin: 0 0 10px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    color: black;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      font-size: 35px;
      line-height: 41px;
      margin: 0 0 45px;
    }
  }

  &__list {
    width: 100vw;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    position: relative;

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    @media screen and (min-width: 992px) {
      padding: 0 0 0 6%;
      overflow: visible;
      position: static;
    }

    &__bankingimage {
      padding: 0 0 0 2%;
      width: 400px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      z-index: 99;
      width: 15vw;
      height: 100%;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      transform: translateY(-50%);

      @media screen and (min-width: 992px) {
        display: none;
      }

      &.v_prev {
        left: 0;
        img {
          transform: rotate(180deg);
        }
      }

      &.v_next {
        right: 0;
      }
    }

    &__items {
      list-style-type: none;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 15vw;
      padding: 0;
      transition: transform 400ms ease-in;
      z-index: 98;

      @media screen and (min-width: 992px) {
        flex-wrap: wrap;
        max-width: 85vw;
        margin: 0;
      }

      &:after {
        content: '';
        display: block;
        flex: 1 0 1px;

        @media screen and (min-width: 992px) {
          display: none;
        }
      }

      &__item {
        padding: 0;
        flex: 1 0 70vw;
        margin-right: 15vw;;

        @media screen and (min-width: 992px) {
          flex: 1;
          height: auto;
          margin: 0;
        }

        &__card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          background-color: transparent;
          box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.08);
          box-sizing: border-box;
          border-radius: 10px;

          @media screen and (min-width: 992px) {
            height: 100%;
            box-sizing: border-box;
          }

          &__info {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__image{
              width: 80%;
            }

            &__title {
              margin: 0 0 26px;
              font-family: 'Raleway';
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              text-align: center;
              text-transform: capitalize;
              color: #1C1C1C;

              @media screen and (min-width: 992px) {
                margin: 0 0 10px;
              }
            }

            &__price {
              margin: 0 0 10px;
              font-family: 'Raleway';
              font-weight: 400;
              font-size: 50px;
              line-height: 59px;
              text-align: center;
              text-transform: uppercase;
              text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
              color: #1C1C1C;

              @media screen and (min-width: 992px) {
                font-size: 70px;
                line-height: 82px;
                margin: 0 0 19px;
              }
            }

            &__description {
              margin: 0 0 18px;
              font-family: 'Raleway';
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              letter-spacing: -0.02em;
              color: #1C1C1C;

              @media screen and (min-width: 992px) {
                line-height: 28px;
                margin: 0 0 56px;
              }
            }
          }

          &__ctas {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__btn {
              align-self: stretch;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 55px;
              margin: 0 0 10px;
              padding: 0;
              border: none;
              font-family: 'Raleway';
              font-weight: 700;
              font-size: 16px;
              line-height: 55px;
              text-align: center;
              text-transform: capitalize;
              text-decoration: none;
              color: black;
              background-color: #1C1C1C;
              border-radius: 60px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
