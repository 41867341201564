.carousel {
    // overflow-x: hidden;
    // -ms-overflow-style: none;  /* Internet Explorer 10+ */
    // scrollbar-width: none;  /* Firefox */
    position: relative;
    // padding: 70px 20px;
    width: 100vw; 
    padding: 0 15vw;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
        width: 56vw; 
        padding: 0 7.5vw;
        // padding: 100px 20px;
        box-sizing: initial;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        z-index: 99;
        width: 15vw;
        height: 100%;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        transform: translateY(-50%);
  
        @media screen and (min-width: 992px) {
          width: 7.5vw;
        }
  
        &.v_prev {
          left: 0;
  
          img {
            transform: rotate(180deg);
          }
        }
  
        &.v_next {
          right: 0;
        }
    }

    &__container {
        overflow-x: hidden;
        width: 70vw; 
        padding: 70px 10px;

        @media screen and (min-width: 992px) {
            width: 56vw; 
            padding: 100px 0;
        }
    }

    &__list {
        list-style-type: none;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // column-gap: 20px;
        // row-gap: 20px;
        margin: 0;
        padding: 0;

        display: flex;
        flex-wrap: nowrap;
        transition: transform 400ms ease-in;
        z-index: 98;

        @media screen and (min-width: 992px) {
            // grid-template-columns: 1fr 1fr 1fr 1fr;
            // column-gap: 60px;
            // row-gap: 60px;
            // width: 56vw; 
        }

        &__item {
            flex: 1 0 35vw;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0 10px;
            box-sizing: border-box;
            
            @media screen and (min-width: 992px) {
                flex: 1 0 14vw;
                padding: 0 20px;
            }

            &__image {
                // flex: 1 0 14vw;
                width: 100%;
                display: block;

                @media screen and (min-width: 992px) {
                    padding: 0 20px;
                }
            }
        }
    }
}