.page_hero_mobile {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 186px;
    left: 0;
    top: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 382px;
    left: 0;
    bottom: 0;
    z-index: 79;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 147px;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &__main {
      font-style: normal;
      font-weight: 700;
      white-space: nowrap;
    }

    &__sub {
      display: block;
      margin-bottom: 53px;
      text-align: center;
      line-height: 40px;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 50px;
    z-index: 89;
    width: 100%;

    &__btn {
      margin: 0 20px;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &__image {
        width: auto;
        height: 28px;
      }
    }
  }
}
