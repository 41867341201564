.loader {

  &__progress {
    background-image: url('../../assets/img/loader_background.png');
    background-repeat: no-repeat;
    background-position-y: 145px;
    width: 145px;
    height: 145px;
    clip-path: url(#mask);
    position: absolute;
  }

  &__progress_background {
    background-image: url('../../assets/img/loader_background.png');
    background-repeat: no-repeat;
    background-position-y: 0px;
    width: 145px;
    height: 145px;
    clip-path: url(#mask);
    position: absolute;
    opacity: 0.14;
  }
}
