.header {

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 91;
    width: 100vw;
    height: 100vh;
    background-color: #1C1C1C;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    @media screen and (min-width: 992px) {
      overflow-y: hidden;
    }

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 90;
      padding: 20px;

      @media screen and (min-width: 992px) {
        padding: 30px 54px 0 39px;
      }

      &__logo {

        &__image {
          width: 106px;
          height: 67px;

          @media screen and (min-width: 992px) {
            width: 117px;
            height: 74px;
          }
        }
      }

      &__content {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &__close_menu_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          padding: 0;
          border: none;
          border-radius: 30px;
          background-color: white;
          cursor: pointer;
          transition: background-color 400ms;

          &__image {
            display: block;
          }
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;

      &__contact_form {
        display: block;
        position: absolute;
        top: 50%;
        width: 100vw;
        padding: 0 20px;
        box-sizing: border-box;

        @media screen and (min-width: 992px) {
          width: 60vw;
          padding: 0 180px;
        }
      }

      &__list {
        list-style-type: none;
        position: absolute;
        top: 50%;
        margin: 0;
        padding: 0 20px;

        @media screen and (min-width: 992px) {
          padding: 0 180px;
        }

        &__item {
          // margin: 0 0 15px;
          margin: 0 0 2vh;
          padding: 0;

          &__link {
            padding: 0;
            border: none;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 31px;
            text-transform: uppercase;
            text-decoration: none;
            color: #FFFFFF;
            background-color: transparent;
            cursor: pointer;
            transition: text-shadow 600ms ease-in;

            &:hover {
              color: #F8C973;
              text-shadow: 0px 0px 28px #F8C973;
            }

            @media screen and (min-width: 768px) {
              font-size: 32px;
              line-height: 40px;
            }

            @media screen and (min-width: 992px) {
              // font-size: 48px;
              // line-height: 56px;
              // font-size: 8.5vh;
              // line-height: 9.5vh;
              // font-size: 7.5vh;
              // line-height: 9.5vh;
              font-size: 5.5vh;
              line-height: 6.5vh;
            }

            @media screen and (min-width: 1280px) {
              // font-size: 60px;
              // line-height: 70px;
              // font-size: 7.5vh;
              // line-height: 9.5vh;
              font-size: 5.5vh;
              line-height: 6.5vh;
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 20px 20px;
      box-sizing: border-box;

      @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 180px 90px;
      }

      &__contact_info {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0 0 35px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #FFFFFF;

        @media screen and (min-width: 992px) {
          flex-direction: row;
          align-items: center;
          padding: 0;
        }

        &__item {
          margin: 0 0 15px;
          padding: 0;

          @media screen and (min-width: 992px) {
            margin: 0 50px 0 0;
          }

          a {
            text-decoration: none;
            color: #FFFFFF;

            &:hover {
              color: #F8C973;
            }
          }
        }
      }

      &__contact_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 211px;
        height: 50px;
        margin: 0;
        border: none;
        border-radius: 60px;
        box-sizing: border-box;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #1C1C1C;
        text-decoration: none;
        background-color: white;
        cursor: pointer;

        .btn_hover_state_background {
          background-color: #F8C973;
        }
      }
    }
  }
}
