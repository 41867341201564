.footer {
  position: relative;
  z-index: 2;
  padding: 50px 75px 60px;
  background-color: white;

  @media screen and (min-width: 992px) {
    padding: 22px 60px 20px;
  }

  &__links {

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 90vw;
      margin: 0 0 62px;
    }

    &__contact {
      text-align: center;

      @media screen and (min-width: 992px) {
        display: flex;
        text-align: left;
      }

      span {
        display: block;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        font-weight: 700;
        color: #1C1C1C;

        @media screen and (min-width: 992px) {
          margin: 0 40px 0 0;
        }

        a {
          text-decoration: none;
          color: #1C1C1C;
        }
      }

      &__social_links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        @media screen and (min-width: 992px) {
          justify-content: flex-start;
          margin-right: 40px;
          margin-bottom: 0;
        }

        &__link {
          display: flex;
          align-items: center;
          height: 28px;
          margin: 0 20px 0 0;
          text-decoration: none;

          &:last-child {
            margin-right: 0;

            @media screen and (min-width: 992px) {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      margin: 30px 0 0;

      @media screen and (min-width: 992px) {
        justify-content: flex-end;
        margin: 0;
      }

      a {
        display: block;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        font-weight: 700;
        color: #1C1C1C;
        text-decoration: none;
        color: #1C1C1C;

        @media screen and (min-width: 992px) {
          margin: 0 0 0 40px;
        }
      }
    }
  }

  .footer__copyright {
    margin: 30px 0 0;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1C1C1C;

    @media screen and (min-width: 992px) {
      margin: 0;
    }
  }
}
