.loading {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
  }

  &__animation {
    display: block; 
  }
}

.canvas {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
