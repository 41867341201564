.adoozy_power_faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  // height: 650px;
  padding: 40px 20px 123px;

  @media screen and (min-width: 992px) {
    padding: 45px 0 123px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    // height: 920px;
    height: 108%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: black;
    transform: skewY(-3deg) translateY(-50px);
  }

  &__title {
    margin: 0 0 87px;
    position: relative;
    z-index: 2;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #E7E7E7;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      margin: 0 0 82px;
      font-size: 70px;
      line-height: 82px;
    }
  }

  &__list {
    list-style-type: none;
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    border-top: 1px solid #3C3C3C;

    @media screen and (min-width: 992px) {
      width: 70vw;
    }

    &__item {
      margin: 0;
      padding: 17px 0 0;
      border-bottom: 1px solid #3C3C3C;

      @media screen and (min-width: 992px) {
        padding: 30px 20px 20px;
      }

      &__question {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 20px;
        padding: 0;
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        color: #E7E7E7;

        @media screen and (min-width: 992px) {
          font-size: 20px;
          line-height: 23px;
          margin: 0 0 10px;
        }

        span {
          flex: 1;
        }

        &__btn {
          position: relative;
          width: 20px;
          height: 20px;
          margin: 0 0 0 40px;
          padding: 0;
          border: none;
          background-color: transparent;
          cursor: pointer;

          @media screen and (min-width: 992px) {
            width: 30px;
            height: 30px;
            margin: 0 0 0 46px;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #F7C973;

            @media screen and (min-width: 992px) {
              width: 30px;
            }
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #F7C973;
            transform: rotate(90deg);

            @media screen and (min-width: 992px) {
              width: 30px;
            }
          }
        }

        &__close_btn {
          position: relative;
          width: 20px;
          height: 20px;
          margin: 0 0 0 40px;
          padding: 0;
          border: none;
          background-color: transparent;
          cursor: pointer;

          @media screen and (min-width: 992px) {
            width: 30px;
            height: 30px;
            margin: 0 0 0 46px;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #F7C973;

            @media screen and (min-width: 992px) {
              width: 30px;
            }
          }
        }
      }

      &__answer {
        margin: 0 0 20px;
        padding: 0;
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #E7E7E7;

        @media screen and (min-width: 992px) {
          line-height: 28px;
          margin: 0 0 10px;
          padding: 0 76px 0 0;
        }

        a {
          color: #E7E7E7;
          text-decoration: none;
        }
      }
    }
  }
}
