
.adoozy-collect {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: white;
  
  &__title {
    margin: 0 0 87px;
    position: relative;
    z-index: 2;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: black;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background-color: white;

    @media screen and (min-width: 992px) {
      margin: 0 0 82px;
      font-size: 70px;
      line-height: 82px;
    }
  }

  &__header {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 20px;
    background-color: white;

    h1 {
      font-size: 28px;
      margin-bottom: 10px;
      color: black;
    }

    p {
      font-size: 16px;
      margin-bottom: 60px;
      color: black;
    }

    .steps-grid3 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;

      @media screen and (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        
      }
    }
    
    .step-card3 {
      display: flex;
      align-items: center;
      padding: 15px;
      background-color: white;
      overflow: hidden;
      transition: box-shadow 0.3s ease;
    
      &__image {
        img {
          height: 150px;
          object-fit: fill;
          border-radius: 25px;

          @media screen and (min-width: 992px) {
              height: 200px;
          }
        }
      }
    
      &__content2 {
        display: flex;
        flex: 1;       
        flex-direction: column;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        border-radius: 0px 25px 25px 0px;
        align-items: start;
        padding: 10px;
        height: 100px;

        @media screen and (min-width: 992px) {
          height:auto;
        }
        
        h2 {
          font-size: 18px;
          @media screen and (min-width: 992px) {
            font-size: 22px;
          }
        }
    
        p {
          font-size: 12px;
          color: #000;

          @media screen and (min-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
