.page {

  &__title {
    margin: 0;
    font-family: Raleway;
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1C1C1C;
    text-transform: uppercase;

    @media screen and (min-width: 992px) { 
      width: 55vw;
      font-size: 70px;
      line-height: 82px;
    }
  }

  &__table {
    padding: 0 20px 100px;

    @media screen and (min-width: 992px) {
      width: 40vw;
      padding: 0 0 100px;
    }

    &__row {

      &__cell {
        vertical-align: top;;
      }
    }
  }
}
