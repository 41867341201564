.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 91;
  width: 100vw;
  height: 100vh;
  background-color: #1C1C1C;
  overflow-y: scroll;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 90;
    padding: 30px 54px 0 39px;

    &__content {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &__close_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 0;
        border: none;
        border-radius: 30px;
        background-color: white;
        cursor: pointer;
        transition: background-color 400ms;

        &__image {
          display: block;
        }
      }
    }
  }

  &__form {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    padding: 0 20px;

    @media screen and (min-width: 992px) {
      width: 70vw;
      padding: 0 180px;
      box-sizing: border-box;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
      padding: 0 180px 90px;
    }

    &__contact_info {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #FFFFFF;

      &__item {
        margin: 0 50px 0 0;
        padding: 0;

        a {
          text-decoration: none;
          color: #FFFFFF;

          &:hover {
            color: #F8C973;
          }
        }
      }
    }

    &__contact_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 211px;
      height: 50px;
      margin: 0;
      border-radius: 60px;
      box-sizing: border-box;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #1C1C1C;
      text-decoration: none;
      background-color: white;
      cursor: pointer;

      .btn_hover_state_background {
        background-color: #F8C973;
      }
    }
  }
}
