
.adoozy-customer_service {
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: white;

    &__header {
        img {
          width: 100%;
          object-fit: cover;

          @media screen and (max-width: 480px) {
            width: 100%;
          } 

        }
    }


    &__subheading {
        font-family: 'Raleway';
        font-size: 15px;
        line-height: 36px;
        text-align: center;
        color: #000;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      
        @media screen and (min-width: 992px) {
          font-size: 20px;
          line-height: 41px;
        }
    }
  
    &__title {
      margin: 0 0 87px;
      position: relative;
      z-index: 2;
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      text-transform: uppercase;
      color: black;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      background-color: white;
  
      @media screen and (min-width: 992px) {
        margin: 0 0 82px;
        font-size: 70px;
        line-height: 82px;
      }
    }

    &__partner{
      background-color: black;
      height: 100px;
    }

    &__content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    &__steps-grid {
      @media screen and (min-width: 992px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }
    
    &__items {
      display: flex;
      align-items: center; 
      padding: 10px;

      a {
        color: #000;
        text-decoration: none;
      }
    }
    
    &__items img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    
    &__social {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      align-items: center; 
      justify-content: center; 
      gap: 10px;

  }
}
  