.copy {
    width: 55vw;
    padding: 0 0 100px;

    h2 {
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.02em; 
        color: #1C1C1C
    }

    h3 {
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 12px;
        line-height: 28px;
        letter-spacing: -0.02em; 
        color: #1C1C1C
    }

    p,
    li {
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.02em; 
        color: #1C1C1C
    }
}