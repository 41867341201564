// @import './assets/fonts/untitled-sans-regular/font';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  background-color: black;
}

#root {
  overflow: hidden;
}

.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 110px;
  line-height: 105px;
  text-transform: uppercase;
  color: #fff;

  span {
    font-weight: 300;
  }
}

.btn {
  position: relative;
  overflow: hidden;
  transition: color 400ms ease-out;

  &:hover {

      .btn_text {
        top: -100%;
      }

      .btn_hover_state_background {
        left: 0;
      }

      .btn_hover_state_text {
        top: 0;
      }
  }

  .btn_text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: top 400ms;
  }

  .btn_hover_state_background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    z-index: 1;
    background: white;
    transition: left 200ms ease-out;
  }

  .btn_hover_state_text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    z-index: 1;
    transition: top 400ms;
  }
}
