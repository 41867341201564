
.adoozy-location {
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: white;

    &__header {
        background-color: black;
        img {
          width: 60%;
          object-fit: cover;
          
          @media screen and (max-width: 480px) {
            width: 100%;
            height: 100%;
          } 
        }
    }
  
    &__title {
      margin: 0 0 87px;
      position: relative;
      z-index: 2;
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      text-transform: uppercase;
      color: black;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      background-color: white;
  
      @media screen and (min-width: 992px) {
        margin: 0 0 82px;
        font-size: 70px;
        line-height: 82px;
      }
    }

    &__content {
        padding: 20px;
        background-color: white;
    
        .steps-grid2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          
          .step-card2 {
            padding: 15px;
            background-color: white;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            overflow: hidden;
            transition: box-shadow 0.3s ease;
    
            &:hover {
              box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            }
    
            &__image {
              img {
                max-width: 80%;
                height: auto;
                object-fit: cover;
                border-radius: 8px;
                cursor: pointer;
              }
            }
          }
        }
    }
}
  