.page_menu {
  position: relative;
  z-index: 999;
  height: 59px;
  background-color: white;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    height: 90px;
  }

  &__container {
    display: flex;
    background-color: #fff;
    position: static;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 0 20px 0 0;
    overflow-x: scroll;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
      justify-content: center;
      overflow-x: hidden;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (min-width: 992px) {
      align-items: center;
      width: 100%;
      max-width: 85vw;
      padding: 34px 0 35px;
    }

    &__item {
      flex: 1 0 auto;
      margin: 0 30px 0 0;
      padding: 0;
      cursor: pointer;

      @media screen and (min-width: 992px) {
        flex: none;
        margin: 0 75px 0 0;
      }

      &__link {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #1C1C1C;
        text-decoration: none;

        @media screen and (min-width: 992px) {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
}
